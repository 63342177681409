@import "~bootstrap/scss/bootstrap";
.developer {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 0;
  position: relative;
  background-color: #d9e7fd;
  padding: 0;
  padding-top: 30px;
  padding-bottom: 20px;
  .developer-text {
    display: inline-block;
    padding-top: 25px;
    padding-right: 10px;
    font-size: 20px;
    color: #4285f4;
    // XS only
    @include media-breakpoint-only(xs) {
      padding-top: 0px;
    }
  }
  .developer-logo {
    display: inline-block;
    background-image: url(../assets/images/lg-autimatisering.svg);
    background-repeat: no-repeat;
    width: 300px;
    height: 82px;
  }
  a {
    display: inline-block;
    font-size: 0px;
    text-decoration: none;
  }
}
