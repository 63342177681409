@import "~bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/variables";

/*variable*/
$color-primary: #ffcc00;
/*BASE*/

/* BREAKPOINTS BOOTSTRAP 5
// Mixins with media queries that define 'up, 'down', 'only' and 'between'
// breakpoints. You can use these to define visibility of your classes,
// for each or a range of specific devices.
// See https://getbootstrap.com/docs/5.1/layout/breakpoints/#media-queries

// XS is often not needed, because Bootstrap is mobile-first

// SM and up        @include media-breakpoint-up(sm) { }
// MD and up        @include media-breakpoint-up(md) { }
// LG and up        @include media-breakpoint-up(lg) { }
// XL and up        @include media-breakpoint-up(xl) { }
// XXL and up       @include media-breakpoint-up(xxl) { }

// XS and down      @include media-breakpoint-down(sm) { }
// SM and down      @include media-breakpoint-down(md) { }
// MD and down      @include media-breakpoint-down(lg) { }
// LG and down      @include media-breakpoint-down(xl) { }
// XL and down      @include media-breakpoint-down(xxl) { }

// XS only          @include media-breakpoint-only(xs) { }
// SM only          @include media-breakpoint-only(sm) { }
// MD only          @include media-breakpoint-only(md) { }
// LG only          @include media-breakpoint-only(lg) { }
// XL only          @include media-breakpoint-only(xl) { }
// XXL only         @include media-breakpoint-only(xxl) { }

// MD to XL         @include media-breakpoint-between(md, xl) { }
// Etc

// Example
// Hide starting at 'min-width: 0', and then show at the 'SM' breakpoint
//   .custom-class {
//     display: none;
//   }
//   @include media-breakpoint-up(sm) {
//     .custom-class {
//       display: block;
//     }
//   }

*/

/* HELPER > WHICH BOOTSTRAP 5 BREAKPOINT IS ACTIVE? */
// Vic: A video explaining what this does is here: https://youtu.be/dQw4w9WgXcQ
.which-res {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2000;
  background-color: red;
  color: #ffffff;
  width: 100px;
  height: auto;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  &::after {
    @media screen and (max-width: 575px) {
      content: "XS";
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
      content: "SM";
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      content: "MD";
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      content: "LG";
    }
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
      content: "XL";
    }
    @media screen and (min-width: 1400px) {
      content: "XXL";
    }
  }
}

/* BASE */
* {
  padding: 0;
  margin: 0;
}
body {
  flex: 1 0 auto;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", Arial, "Helvetica Neue", Geneva, Tahoma, sans-serif;
  background-image: url("assets/images/bg-repeat-2.png");
  // background-size: 300px;

  background-repeat: repeat;
  a {
    text-decoration: none;
    color: #000000;
  }
  input[type] {
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
      border-color: #ffcc00;
    }
  }
}
* .Maincontainer ::-webkit-scrollbar {
  width: 15px;
}

* .Maincontainer ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.175);
  border: 4px solid transparent;
  border-radius: 20px;
  background-clip: padding-box;
}
.custom-file-button {
  input[type="file"] {
    margin-left: -2px !important;

    &::-webkit-file-upload-button {
      display: none;
    }
    &::file-selector-button {
      display: none;
    }
  }

  &:hover {
    label {
      background-color: #dde0e3;
      cursor: pointer;
    }
  }
}

// COOKIES
#cookies {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  position: fixed;
  bottom: 0;
  color: #000000;
  background-color: #ffffff;
  z-index: 600;
  display: none;
  .cookies {
    width: 80%;
    margin: auto;
    min-height: 180px;
    align-items: center;
    // a{
    //   color: #ffcc00;
    //   font-weight: 500;
    //   text-decoration: none;
    // }
    button {
      border-radius: 5px;
      padding: 8px 12px 8px 12px;
      margin-left: 12px;
      margin-right: 12px;
      font-size: 1rem;
      font-family: inherit;
      cursor: pointer;
      border: 3px solid $color-primary;
      background-color: #ffffff;
      color: #000000;
      &:hover {
        background-color: $color-primary;
      }
    }
  }
}

// MAIN CONTAINER
.ingredient-ul-list {
  padding-left: 0;
  margin: 8px;
  li {
    display: inline-block;
    border-right: 1px dotted black;
    margin-right: 12px;
    padding-right: 12px;
    &:last-child {
      border: none;
    }
  }
}
.Maincontainer {
  // min-height: calc(100vh - 213px);
  min-height: calc(100vh - 137px);
  margin: auto;
  text-align: start;
  padding-top: 81px;
  padding-bottom: 2rem;
}

.logo {
  width: 250px;
}
.header-text {
  text-align: center;
}

.error-img {
  width: 50%;
  margin: auto;
}

.navbar {
  z-index: 1;
  height: 75px;
  .navbar-nav {
    background-color: #f7f4e7;
    border: 1px solid #d6d6d7;
    border-radius: var(--bs-border-radius);
  }
  .dropdown {
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
  }
  #basic-nav-dropdown {
    padding-bottom: 0;
    &:hover,
    &:focus,
    &:active {
      color: $color-primary;
    }
  }
}
.navbar-items.nav-link {
  padding-right: 0.5em;
  padding-left: 0.5em;
  padding-top: 0.5em;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  background: none;
  display: block;
  &:hover,
  &:focus,
  &:active {
    color: $color-primary;
  }
}
.dropdown-menu a {
  &:hover,
  &:focus,
  &:active {
    background-color: #ffffff;
  }
}
.drop-items {
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  background: none;
  display: block;
  &:hover,
  &:focus,
  &:active {
    color: $color-primary;
    background-color: #ffffff;
  }
}

.placeholderInputs::placeholder {
  font-style: italic;
}
h4 {
  text-align: center;
}

.back-button {
  position: absolute;
  top: 88px;
  z-index: 0;
}
.buttons {
  margin-top: 12px;
}
// NAVBAR
.navbar-items-active {
  color: $color-primary !important;
  font-weight: 500 !important;
}

// HISTORY PAGE
.history-style {
  position: relative;
  gap: 10px;
  .history-dishes {
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 0.7rem;
    background: #ffffff;
    .history-img {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      height: 75%;
      border-radius: 0.2rem;
    }
  }
  .h-text {
    height: 60vh;
    background: #ffffff;
    padding: 12px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.175);
    overflow: auto;
    border-radius: 0.7rem;
    p {
      margin-bottom: 0;
    }
  }
  .history-header {
    margin-bottom: 8px;
    font-size: smaller;
  }
}
.historyText {
  max-height: 50vh;
  height: 20rem;
  margin-top: 2rem;
  background: #ffffff;
  padding: 2rem;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.175);
  overflow: auto;
  border-radius: 0.7rem;
}
.p-div {
  text-align: center;
  margin-bottom: 34px;
  // background: #ffffff;
  border-radius: 0.7rem;
  margin-right: auto;
  margin-left: auto;
  width: fit-content;
  padding: 12px;
  font-size: large;
}
.carousel-item {
  padding-bottom: 6px;
}

.carousel-control-next-icon {
  background: #000000;
  padding-top: 10rem;
  padding-bottom: 10rem;
  border-radius: 1rem;
  color: #fbf8d3;
}
.carousel-control-prev-icon {
  background: #000000;
  padding-top: 10rem;
  padding-bottom: 10rem;
  border-radius: 1rem;
  color: #fbf8d3;
}

//HOME PAGE
.home-style {
  margin-bottom: 24px;
  height: auto;
  min-height: 60vh;
  .home-dishes {
    border-radius: 0.7rem;
    border: 1px solid rgba(0, 0, 0, 0.175);
    padding: 12px;
    max-height: 60vh;
    background: #ffffff;
    text-align: center;
    .h-img {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 0.2rem;
      width: 100%;
      height: 75%;
    }
  }

  .home-header {
    margin-bottom: 8px;
    font-size: smaller;
  }
  .h-text {
    height: 60vh;
    background: #ffffff;
    padding: 12px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.175);
    overflow: auto;
    border-radius: 0.7rem;
    p {
      margin-bottom: 0;
    }
  }
}

.upload-image {
  padding-top: 2rem;
  width: 25%;
}
.login-inputs {
  margin-top: 12px !important;
  max-width: 50%;
  margin: auto;
  border-radius: 0.7rem;
}
.single-dishes {
  // margin-top: 12px !important;
  text-align: center;
  border-radius: 1rem;
  background: #ffffff;
  padding: 1rem;
  height: auto;
  & .div-recept,
  & .div-ingredient {
    padding: 0.5rem;
    max-height: 10em;
    overflow-y: auto;
  }

  .div-img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 0.5rem;
  }
  .single-header {
    margin-bottom: 8px;
    p {
      margin-bottom: 0;
    }
  }
}

// ONE DISH PAGE
.single-dishes-admin {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  border-radius: 0.7rem;
  background: #ffffff;
  padding: 12px;
  .md-editor {
    max-width: 41vw;
  }
  .div-img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 0.2rem;
  }
}

// ALL DISHES PAGE
.all-select {
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: 50%;
}
.all-row {
  text-align: center;
  .col {
    padding-left: 6px;
    padding-right: 6px;
  }
  .all-cols {
    max-height: max-content;
    background: #ffffff;
    border-radius: 0.7rem;
    border: 1px solid rgba(0, 0, 0, 0.175);
    padding: 12px;
    margin-bottom: 12px;
    a:hover {
      box-shadow: 0 4px 8px 0 $color-primary, 0 8px 20px 0 rgba(0, 0, 0, 0.7);
    }
    .all-img {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      height: 30vh;
      border-radius: 0.2rem;
    }
    .all-btns {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
    }
  }
}

//TOP FOODS PAGE
.top-items {
  text-align: end;
  text-align-last: end;
  padding-inline: 6px;
  .dropdown {
    top: 6px;
  }
}
.top-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 0.2rem;
}
.teller {
  color: rgba(0, 0, 0, 0.6);
}
.rateNum {
  color: $color-primary;
  font-size: xx-large;
}
input[type="radio"] {
  display: none;
}

.star {
  margin-bottom: 0.5rem;
  cursor: pointer;
  fill: $color-primary;
  font-size: smaller;
}
.top-ten-num {
  color: $color-primary;
  min-width: 19px;
}

.top-rows-tree {
  background: #ffffff;
  border-radius: 0.7rem;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 12px;
  a:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.2);
  }
  .top-star {
    width: 100%;
    h5 {
      font-size: x-large;
    }
    font-size: large;
    margin-top: 0;
    margin-bottom: auto;
    margin-left: 12px;
    .top-star-name {
      display: flex;
      justify-content: space-between;
    }
  }
}
.top-rows-ten {
  background: #ffffff;
  border-radius: 0.7rem;
  margin-bottom: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.top-parent-row a {
  color: #000000;
  &:hover {
    font-weight: 600;
  }
}

// SERACH PAGE
.search-result {
  background: #ffffff;
  border-radius: 0.7rem;
  padding-bottom: 12px;
  padding-top: 12px;
  margin-top: 12px;
  text-align: center;
  .search-cols {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    .search-img {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      border-radius: 0.2rem;
      min-height: 10rem;
      display: flex;
      align-items: center;
      h5 {
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-shadow: 1px 1px 2px #000000, 0 0 1em $color-primary,
          0 0 0.2em $color-primary;
        width: 100%;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

// LOGIN PAGE
.p-login {
  font-size: xx-large;
  text-align: center;
}
.login-div {
  background: #ffffff;
  border-radius: 0.7rem;
  border: 1px solid rgba(0, 0, 0, 0.175);
  width: 70%;
  margin: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 6px;
  padding-right: 6px;
  .row {
    width: 100%;
    margin: auto;
  }
  .col {
    margin-top: 6px;
    margin-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
  }
  button {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    background: #ffdc4d;
    border-radius: 0.2rem;
    text-decoration: none;
    color: #000000;
    &:hover {
      background: #fbef80;
    }
  }
}

.inputs-add {
  padding-top: 6px;
  padding-bottom: 6px;
}
.add-ingredient-icon {
  transform: rotate(90deg);
}
.add-padding {
  padding-top: 6px;
  padding-bottom: 6px;

  .input-group-text {
    width: 40px;
    background-color: #ffffff;
  }
}
// HELP AND IDEA PAGES
.accordion-item {
  margin-bottom: 12px;
  border-radius: 0.7rem;
  &:first-of-type,
  &:last-of-type {
    border-radius: 0.7rem !important;
  }
  .accordion-button {
    border-radius: 0.7rem;
    &:hover,
    &:active,
    &:focus {
      background-color: #fbef80;

      box-shadow: inset 0 calc(-1 * 1px) 0 #fbef80;
    }

    &:not(.collapsed) {
      background-color: #fbef80;
      box-shadow: inset 0 calc(-1 * 1px) 0 #fbef80;
    }

    &:first-of-type,
    &:last-of-type {
      border-radius: 0.7rem !important;
    }
  }
}

// MEDIA MD DOWN
@include media-breakpoint-down(md) {
  .top-items {
    text-align-last: center;
    margin-bottom: 18px;
    #input-group-dropdown-1 {
      width: 100%;
    }
  }
  .top-star .top-star-name h5 {
    font-size: large;
  }
  .rateNum {
    font-size: x-large;
  }
  .dropdown-menu {
    width: 100%;
  }
  .login-inputs {
    max-width: 100%;
  }
  .back-button {
    top: 81px;
  }
  .login-div {
    width: 100%;
  }
  .home-style {
    max-height: 60em;
    .col {
      padding: 0;
    }
    .home-dishes {
      padding: 12px;
      height: 30em;
    }
    .h-text {
      margin-top: 12px;
      height: 30em;
    }
  }
  .history-style {
    display: grid;
    max-height: fit-content;
    grid-template-rows: 0.9fr 1fr;
    .history-dishes {
      padding: 12px;
    }
  }
  .upload-image {
    padding-top: 12px;
    width: 50%;
  }
  .carousel-control-next {
    height: 100px;
    margin-top: 10rem;
    .carousel-control-next-icon {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }
  .carousel-control-prev {
    height: 100px;
    margin-top: 10rem;
    .carousel-control-prev-icon {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }
  .single-dishes-admin {
    display: flex;
    flex-direction: column;
    align-items: center;
    .md-editor {
      max-width: 90vw;
    }
    .div-img {
      width: 100%;
      height: 45vh;
    }
    form {
      width: 100%;
    }
  }
  .single-dishes {
    .div-img {
      min-height: 450px;
    }
  }

  .top-img {
    height: 100px;
    width: 100px;
  }
}

// MEDIA MD UP
@include media-breakpoint-up(md) {
  .top-rows-ten {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .single-dishes {
    .div-img {
      min-height: 400px;
    }
    text-align: start;
    .div-recept {
      max-height: 30em;
      overflow-y: auto;
    }
    .div-ingredient {
      max-height: 10em;
      overflow-y: auto;
    }
  }
  .history-style {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 24px;

    .history-dishes {
      padding: 12px;
      max-height: 55vh;
    }
    .h-text {
      max-height: 55vh;
    }
  }
  .carousel {
    .carousel-item {
      padding-bottom: 24px;
    }
    .carousel-control-prev {
      margin-left: -3rem;
      width: 5%;
    }
    .carousel-control-next {
      margin-right: -3rem;
      width: 5%;
    }
  }

  .p-div {
    width: 50%;
  }
  .star {
    font-size: larger;
  }
  .historyText {
    margin-top: 0px;
    height: 100%;
  }
  .top-img {
    height: 140px;
    width: 140px;
  }
  .all-select {
    max-width: 30%;
  }
}

//MEDIA LG UP
@include media-breakpoint-up(lg) {
  .navbar .navbar-nav {
    background-color: rgba(
      var(--bs-tertiary-bg-rgb),
      var(--bs-bg-opacity)
    ) !important;
    border: none;
  }
  .top-img {
    height: 180px;
    width: 180px;
  }
  .navbar-items {
    &:not(:last-child)::after {
      content: "";
      border: 1px solid #000000a8;
      border-width: 0 1px 0 0;
      position: absolute;
      padding-right: 7px;
      margin-top: 4px;
      height: 20%;
    }
  }

  .top-rows-ten {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .carousel {
    .carousel-item {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  .historyText {
    height: 100%;
    margin-top: 0px;
  }
  .carousel-control-prev {
    margin-left: -3rem;
  }
  .carousel-control-next {
    margin-right: -3rem;
  }
}

// MEDIA XL UP
@include media-breakpoint-up(xl) {
  .top-items .header-text {
    margin-right: 5rem;
  }
  .historyText {
    height: 100%;
    margin-top: 0px;
  }
  .carousel-control-prev {
    margin-left: -4rem;
  }
  .carousel-control-next {
    margin-right: -4rem;
  }
}

/* HACKS */
.Maincontainer h3 {
  font-weight: 700;
  font-style: italic;
}
